import { modalsTypes } from '@ducks/modals';
import modals from '@src/utils/constants/modals';
import requestStatus from '@src/utils/helpers/requestStatus';
import types from './types';
import initialState from './initialState';

const recipeReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RECIPE_INGREDIENTS_UPDATE: {
      return {
        ...state,
        recipeItem: action.updatedRecipe
      };
    }
    case types.RECIPE_PENDING: {
      return { ...state, pending: true };
    }
    case types.RECIPE_FULFILLED: {
      return {
        ...state,
        pending: false,
        recipeItem: action.response,
        error: action.response.error
      };
    }
    case types.RECIPE_REJECTED: {
      return {
        ...state,
        pending: false,
        error: action.error.message
      };
    }
    case types.IMPORT_SHARED_RECIPE_PENDING: {
      return { ...state, importSharedRecipeStatus: requestStatus.pending };
    }
    case types.IMPORT_SHARED_RECIPE_FULFILLED: {
      return {
        ...state,
        importSharedRecipeStatus: requestStatus.fulfilled,
        importedRecipeId: action.importedRecipeId
      };
    }
    case types.IMPORT_SHARED_RECIPE_REJECTED: {
      return {
        ...state,
        importSharedRecipeStatus: requestStatus.rejected,
        error: action.error
      };
    }
    case types.RECIPE_HASH_PENDING: {
      return { ...state, shareRecipeTokenPending: true };
    }
    case types.RECIPE_HASH_FULFILLED: {
      return {
        ...state,
        shareRecipeTokenPending: false,
        recipeShareHash: action.response,
        error: action.response.error
      };
    }
    case types.RECIPE_HASH_REJECTED: {
      return {
        ...state,
        shareRecipeTokenPending: false,
        error: action.error.message
      };
    }
    case types.POST_RECIPE_REPORT_PENDING: {
      return { ...state, postRecipeReportStatus: requestStatus.pending };
    }
    case types.POST_RECIPE_REPORT_FULFILLED: {
      return {
        ...state,
        postRecipeReportStatus: requestStatus.fulfilled
      };
    }
    case types.POST_RECIPE_REPORT_REJECTED: {
      return { ...state, postRecipeReportStatus: requestStatus.rejected };
    }
    case types.PUT_RECIPE_PENDING: {
      return { ...state, putRecipePending: true };
    }
    case types.PUT_RECIPE_FULFILLED: {
      return {
        ...state,
        putRecipePending: false,
        id: action.id
      };
    }
    case types.PUT_RECIPE_REJECTED: {
      return { ...state, putRecipePending: false };
    }
    case types.PUT_RECIPE_FOODTYPE_PENDING: {
      return { ...state, putRecipeFoodtypePending: true };
    }
    case types.PUT_RECIPE_FOODTYPE_FULFILLED: {
      return {
        ...state,
        putRecipeFoodtypePending: false
      };
    }
    case types.PUT_RECIPE_FOODTYPE_REJECTED: {
      return { ...state, putRecipeFoodtypePending: false };
    }
    case types.DELETE_RECIPE_PENDING: {
      return { ...state, deleteRecipePending: true };
    }
    case types.DELETE_RECIPE_FULFILLED: {
      return {
        ...state,
        deleteRecipePending: false,
        id: action.id
      };
    }
    case types.DELETE_RECIPE_REJECTED: {
      return { ...state, deleteRecipePending: false };
    }
    case types.POST_RECIPE_IMAGE_PENDING: {
      return { ...state, postRecipeImagePending: true };
    }
    case types.POST_RECIPE_IMAGE_FULFILLED: {
      return {
        ...state,
        postRecipeImagePending: false
      };
    }
    case types.POST_RECIPE_IMAGE_REJECTED: {
      return { ...state, postRecipeImagePending: false };
    }
    case types.GET_RATING_PENDING: {
      return { ...state, getRecipeRatingStatus: requestStatus.pending };
    }
    case types.GET_RATING_FULFILLED: {
      return {
        ...state,
        recipeItem: {
          ...state.recipeItem,
          rating: action.response
        },
        getRecipeRatingStatus: requestStatus.fulfilled
      };
    }
    case types.GET_RATING_REJECTED: {
      return {
        ...state,
        recipeItem: {
          ...state.recipeItem,
          rating: {
            ...state.recipeItem.rating,
            ratingAverage: 0,
            ratingCount: 0,
            ratingSum: 0,
            userRecipeRating: 0
          }
        },
        getRecipeRatingStatus: requestStatus.rejected
      };
    }
    case types.PUT_RATING_PENDING: {
      return { ...state, putRecipePending: true };
    }
    case types.PUT_RATING_FULFILLED: {
      return {
        ...state,
        putRecipePending: false
      };
    }
    case types.PUT_RATING_REJECTED: {
      return { ...state, putRecipePending: false };
    }
    case types.SET_USER_RATING: {
      return {
        ...state,
        recipeItem: {
          ...state.recipeItem,
          rating: {
            ...state.recipeItem.rating,
            userRatingRecipeId: action.id,
            userRating: action.rating
          }
        }
      };
    }
    case types.RESET_RECIPE: {
      return initialState;
    }
    case types.RESET_RECIPE_ID: {
      return {
        ...state,
        id: undefined
      };
    }
    case modalsTypes.HIDE_MODAL: {
      if (
        action.id === modals.LOG_RECIPE_MODAL_ID &&
        !action.doNotDeleteAfter
      ) {
        return { ...state, recipeItem: {} };
      }
      return state;
    }
    default:
      return state;
  }
};

export default recipeReducer;
