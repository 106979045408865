export default {
  RECIPE_INGREDIENTS_UPDATE: 'recipe/RECIPE_INGREDIENTS_UPDATE',
  RECIPE_PENDING: 'recipe/RECIPE_PENDING',
  RECIPE_FULFILLED: 'recipe/RECIPE_FULFILLED',
  RECIPE_REJECTED: 'recipe/RECIPE_REJECTED',
  RECIPE_HASH_PENDING: 'recipe/RECIPE_HASH_PENDING',
  RECIPE_HASH_FULFILLED: 'recipe/RECIPE_HASH_FULFILLED',
  RECIPE_HASH_REJECTED: 'recipe/RECIPE_HASH_REJECTED',
  IMPORT_SHARED_RECIPE_PENDING: 'recipe/IMPORT_SHARED_RECIPE_PENDING',
  IMPORT_SHARED_RECIPE_FULFILLED: 'recipe/IMPORT_SHARED_RECIPE_FULFILLED',
  IMPORT_SHARED_RECIPE_REJECTED: 'recipe/IMPORT_SHARED_RECIPE_REJECTED',
  POST_RECIPE_REPORT_PENDING: 'recipe/POST_RECIPE_REPORT_PENDING',
  POST_RECIPE_REPORT_FULFILLED: 'recipe/POST_RECIPE_REPORT_FULFILLED',
  POST_RECIPE_REPORT_REJECTED: 'recipe/POST_RECIPE_REPORT_REJECTED',
  DELETE_RECIPE_PENDING: 'recipe/DELETE_RECIPE_PENDING',
  DELETE_RECIPE_FULFILLED: 'recipe/DELETE_RECIPE_FULFILLED',
  DELETE_RECIPE_REJECTED: 'recipe/DELETE_RECIPE_REJECTED',
  PUT_RECIPE_PENDING: 'recipe/PUT_RECIPE_PENDING',
  PUT_RECIPE_FULFILLED: 'recipe/PUT_RECIPE_FULFILLED',
  PUT_RECIPE_REJECTED: 'recipe/PUT_RECIPE_REJECTED',
  PUT_RECIPE_FOODTYPE_PENDING: 'recipe/PUT_RECIPE_FOODTYPE_PENDING',
  PUT_RECIPE_FOODTYPE_FULFILLED: 'recipe/PUT_RECIPE_FOODTYPE_FULFILLED',
  PUT_RECIPE_FOODTYPE_REJECTED: 'recipe/PUT_RECIPE_FOODTYPE_REJECTED',
  POST_RECIPE_IMAGE_PENDING: 'recipe/POST_RECIPE_IMAGE_PENDING',
  POST_RECIPE_IMAGE_FULFILLED: 'recipe/POST_RECIPE_IMAGE_FULFILLED',
  POST_RECIPE_IMAGE_REJECTED: 'recipe/POST_RECIPE_IMAGE_REJECTED',
  GET_RATING_PENDING: 'recipe/GET_RATING_PENDING',
  GET_RATING_FULFILLED: 'recipe/GET_RATING_FULFILLED',
  GET_RATING_REJECTED: 'recipe/GET_RATING_REJECTED',
  PUT_RATING_PENDING: 'recipe/PUT_RATING_PENDING',
  PUT_RATING_FULFILLED: 'recipe/PUT_RATING_FULFILLED',
  PUT_RATING_REJECTED: 'recipe/PUT_RATING_REJECTED',
  DELETE_RATING_PENDING: 'recipe/DELETE_RECIPE_PENDING',
  DELETE_RATING_FULFILLED: 'recipe/DELETE_RATING_FULFILLED',
  DELETE_RATING_REJECTED: 'recipe/DELETE_RATING_REJECTED',
  RESET_RECIPE: 'recipe/RESET_RECIPE',
  RESET_RECIPE_ID: 'recipe/RESET_RECIPE_ID',
  SET_USER_RATING: 'recipe/SET_USER_RATING'
};
